<template>
  <v-card>
    <v-card-title class="text-subtitle-1">
      <v-icon class="pr-3">{{ icon }}</v-icon>
      <span>{{ title }}</span>
    </v-card-title>
    <v-row>
      <v-col md="8">
        <GChart type="LineChart" :data="data" :options="option" />
      </v-col>
      <v-col md="2">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Picker without buttons"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
        </v-menu>
        <!-- <v-date-picker v-model="startDate"></v-date-picker>
          <v-time-picker v-model="startTime" format="ampm"></v-time-picker>

          <v-date-picker v-model="endDate"></v-date-picker>
          <v-time-picker v-model="endTime" format="ampm"></v-time-picker>
           -->
        <v-select
          v-model="chartName"
          :items="chartsList"
          item-text="value"
          label="Chart type"
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
</template>
<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import router from "@/router";
import gRPCService from "@/services/grpc.service";
import structureProtoDef from "@/grpc/msh/service/v1/structure_manager_pb";
import timestampProto from "@/grpc/google/protobuf/timestamp_pb";
import eventProtoDef from "@/grpc/msh/resource/v1/event_pb";
import commonDef from "@/grpc/msh/common/v1/common_pb";
import { BREADCRUMB_SET_REQUEST } from "@/store/constants";
import { GChart } from "vue-google-charts";

export default {
  data() {
    return {
      data: null,
      option: null,
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      chartName: "",
      chartsList: [
        { id: "temperature", value: "Temperature" },
        { id: "airQuality", value: "Air Quality" },
        { id: "humidity", value: "% Humidity" },
        { id: "activityLevel", value: "Activity Level" },
      ],
      //   Will need title / range / ...
    };
  },
  components: { GChart },
  methods: {
    setStructure(struct) {
      this.structure = struct;
      this.chartName = router.currentRoute.params.chartName;
      //   if (this.structure.stats.totalAlerts > 0) {
      //     // get alerts
      //     const getAlertsRequest = new alertProtoDef.GetAlertsRequest([
      //       router.currentRoute.params.structureId,
      //       [["", ""], 1],
      //       ["", 1],
      //     ]);
      //     gRPCService
      //       .alert()
      //       .getAlerts(getAlertsRequest, {})
      //       .then((alertsResponse) => {
      //         if (alertsResponse && alertsResponse.getAlertsList().length > 0) {
      //           this.currentAlert = alertsResponse.getAlertsList()[0].toObject();
      //         } else {
      //           this.currentAlert = null;
      //         }
      //       });
      //   }
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 15);
      const dateStart = new timestampProto.Timestamp();
      dateStart.fromDate(yesterday);
      const dateEnd = new timestampProto.Timestamp();
      dateEnd.fromDate(today);
      const dateRange = { start: dateStart, end: dateEnd };
      const protoRange = new commonDef.TimeRange();
      protoRange.setStartTime(dateRange.start);
      protoRange.setEndTime(dateRange.end);
      const eventFilter = new eventProtoDef.EventFilter();
      eventFilter.setTimeRange(protoRange);
      const protoPage = new commonDef.Page();
      protoPage.setPerPage(15);
      protoPage.setCursor("");
      const tempRequest = new structureProtoDef.GetTemperaturesRequest();
      tempRequest.setStructureId(router.currentRoute.params.structureId);
      tempRequest.setFilter(eventFilter);
      tempRequest.setPage(protoPage);
      switch (router.currentRoute.params.chartName) {
        case "temperature":
        case "activityLevel":
        case "humidity":
        case "airQuality":
          gRPCService
            .structure()
            .getTemperatures(tempRequest, {})
            .then((tempResponse) => {
              let temperature_values = [];
              let temperature_labels = [];
              this.data = [];
              this.data.push(["Time", "Temperature"]);
              tempResponse.toObject().eventsList.forEach((element) => {
                let formattedTimestamp = this.$d(
                  new Date(element.temperature.timestamp.seconds) * 1000,
                  "short"
                );
                temperature_values.push(
                  Math.round(element.temperature.trait.temperature.value)
                );
                temperature_labels.push(formattedTimestamp);
                this.data.push([
                  formattedTimestamp,
                  Math.round(element.temperature.trait.temperature.value),
                ]);
              });

              //   if (temperature_values.length > 0) {
              //         let sum = temperature_values.reduce((a, b) => a + b, 0);
              //         this.temperatures.average = sum / temperature_values.length || 0;
              //         this.temperatures.range = "12 hrs"; // TODO Fix range with value from call ?
              //         this.temperatures.highest = Math.max(...temperature_values);
              //         this.temperatures.highest_timestamp =
              //         temperature_labels[
              //             temperature_values.indexOf(this.temperatures.highest)
              //         ];
              //   }
              this.option = {
                chart: {
                  title: "Temperature",
                },
                legend: {
                  position: "none",
                },
                height: 500,
              };
              this.loading = false;
            });
        default:
          console.log("What should we do on default ?");
      }
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
    icon() {
      switch (router.currentRoute.params.chartName) {
        case "temperature":
        case "activityLevel":
        case "humidity":
        case "airQuality":
        default:
          return "mdi-home";
      }
    },
    title() {
      return "Temperature";
    },
  },
  async beforeRouteEnter(to, from, next) {
    let request = new structureProtoDef.GetStructuresRequest([
      to.params.structureId,
      true,
      ["", 1],
      false,
    ]);
    gRPCService
      .structure()
      .getStructures(request, {})
      .then((structuresResponse) => {
        next((vm) => {
          let structure = structuresResponse
            .getStructuresList()[0]
            .getBusiness()
            .toObject();
          store.dispatch(BREADCRUMB_SET_REQUEST, [
            { title: structure.label + " / Temperature" },
          ]);
          vm.setStructure(structure);
        });
      });
  },
};
</script>
